
// Styling 
import "../Styling/AWDAngebotPage.css"

// Components 
import AWDPageNavbar from "../../Common/Components/PageNavbar/AWDPageNavbar";
import AWDPageTeaser from "../../Common/Components/PageTeaser/AWDPageTeaser";
import AWDImageCard from "../../Common/Components/ImageCard/AWDImageCard";
import AWDAngebotVariant from "./AWDAngebotVariant";
import AWDAngebotInputFormular from "./AWDAngebotInputFormular";
import AWDPageFooter from "../../Common/Components/PageFooter/AWDPageFooter";
import { useState, useEffect } from "react";

export default function AWDAngebotPage() {
    
    return (
        <div className="AWDPageContainer">
            <AWDPageNavbar />
            <PageTeaser />
            <PageContent />
            <AWDPageFooter />
        </div>
    )
}

function PageTeaser() {
    return (
        <>
            <AWDPageTeaser
                id={"angebotTeaser"}
                title={"Wir für Ihr Projekt"}
                subTitles={["Beratung", "Angebot"]}
                imageSrc={"/imageRessource/alpinimages/angebot/angebotAngebotImage1.jpg"}
                imageAlt={"teaser angebot"}
            />
        </>
    )
}

function PageContent() {
    return (
        <>
            <div className="AWDPageContent">
                <div className="angebotCardGrid">

                    <p className="angebotTitle">Fordern sie jetzt ein kostenloses Angebot an.</p>
                    <p className="angebotSubTitle">Wir beraten Sie gerne bei jedem Ihrer Projekte</p>

                    <OfferGrid />

                    <p className="angebotTitleFormular">Nutzen sie unser Angebotsformular</p>

                    <AWDAngebotInputFormular />

                </div>
            </div>
        </>
    )
}

function OfferGrid() {

    const [isSmallScreen, setIsSmallScreen] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 800) {
                setIsSmallScreen(true);
            } else {
                setIsSmallScreen(false);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <div className="angebotVariantImageGrid">

                <AWDImageCard
                    id={""}
                    gridArea={"angebotImageDetailMap"}
                    imageSrc={"/imageRessource/alpinimages/angebot/angebotMapDetailImage.jpg"}
                    altText={""}
                />

                {!isSmallScreen &&
                    <AWDImageCard
                        id={""}
                        gridArea={"vehicleImage"}
                        imageSrc="/imageRessource/alpinimages/winterdienst/fahrzeuge/fuhrparkMobileTeaserImage.jpg"
                        altText={""}
                    />
                }

                <AWDAngebotVariant
                    title={"030/684 36 01"}
                    imageSrc={"/imageRessource/stockimages/icons/phoneIcon.png"}
                    imageAlt={"phoneIcon"}
                    gridArea={"angebotPhone"}
                />

                <AWDAngebotVariant
                    title={"mail@alpin-berlin.de"}
                    imageSrc={"/imageRessource/stockimages/icons/mailIcon.png"}
                    imageAlt={"mailIcon"}
                    gridArea={"angebotMail"}
                />

                <AWDImageCard
                    id={""}
                    gridArea={"angebotImageMap"}
                    imageSrc={"/imageRessource/alpinimages/angebot/angebotMapImage.jpg"}
                    altText={""}
                />
            </div>
        </>
    )
}
