
import { NavLink } from "react-router-dom";
import "./AWDLinkButton.css"
import "./AWDRoundedLinkButton.css"

export default function AWDRoundedLinkButton(
    props: { 
        buttonText: string
        link: string
        gridArea: string 
    }
) {

    const buttonText = props.buttonText;
    const link = props.link;
    const gridArea = props.gridArea;
   
    return (
        <NavLink
            to={link}
            className="linkButton"
            id="roundedLinkButton"
            style={{ gridArea: gridArea }}
        >
            {buttonText}
        </NavLink>
    );
}