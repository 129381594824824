import AWDPageFooter from "../../Common/Components/PageFooter/AWDPageFooter";
import AWDPageNavbar from "../../Common/Components/PageNavbar/AWDPageNavbar";

// Styling 
import "../Styling/AWDWinterdienstRefPage.css"
import AWDWinterdienstRefCard from "./AWDWinterdienstRefCard";

export default function AWDWinterdienstRefPage() {

    const referenzen = [
        ["Charite", "CFMFacilityManagementGmbH"],
        ["Gewerbesiedlungs-GesellschaftmbH", ""],
        ["Wohnungsbaugenossenschaft \"Treptow Süd\" e. G.", ""],
        ["Wohnungsbaugenossenschaft \"Köpenick Nord\" e. G.", ""],
        ["Malankowski Gebäudereinigung GmbH", ""],
        ["Becker & Kries", " Immobilien Management GmbH & Co. KG"],
        ["FrankBehrend", "WohnungsverwaltungGmbH"],
        ["COTRAC", "Liegenschaften & Hausverwaltungen GmbH"],
        ["Heimstaden", "BerlinGmbH"]
    ]

    return (
        <div className="AWDPageContainer">
            <AWDPageNavbar />
            <div className="AWDBasicPageContent">
                <div className="winterdienstRefContentGrid">
                    <p className="winterdienstRefTitle">Unsere Winterdienst Referenzen in Berlin</p>
                    <p className="winterdienstRefSubTitle">Wir betreuen Weltkonzerne</p>
                    <div className="winterdienstRefGrid">
                        {referenzen.map((ref, index) => (
                            <AWDWinterdienstRefCard
                                title={ref[0]}
                                description={ref[1]}
                                gridArea={"ref" + (index + 1)}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <AWDPageFooter />
        </div>
    )
}