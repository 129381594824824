export const AWDWinterdienstValidZipCodes: number[] = [
    // Berlin Charlottenburg Wilmersdorf
    13627,
    13629,
    13597,
    14050,
    14052,
    14053,
    14055,
    14057,
    14059,
    14193,
    14995,
    14197,
    14199,
    10589,
    10587,
    10585,
    10623,
    10625,
    10627,
    10629,
    10711,
    10709,
    10707,
    10719,
    10789,
    10777,
    10713,
    10715,
    10717,
    10777,
    10779,

    // Mitte 
    13351,
    13349,
    13347,
    13359,
    13357,
    13355,
    13353,

    10553,
    10551,
    10559,
    10555,
    10557,
    10787,
    10785,
    10115,
    10117,
    10119,
    10178,
    10179,
    10119,

    // weitere 

    10115,
    10117,
    10119,
    10178,
    10179,
    10243,
    10245,
    10247,
    10249,
    10315,
    10317,
    10318,
    10319,
    10365,
    10367,
    10405,
    10407,
    10409,
    10435,
    10437,
    10439,
    10551,
    10553,
    10555,
    10557,
    10559,
    10585,
    10587,
    10589,
    10623,
    10625,
    10627,
    10707,
    10709,
    10711,
    10713,
    10715,
    10717,
    10719,
    10777,
    10779,
    10781,
    10783,
    10785,
    10787,
    10789,
    10823,
    10825,
    10827,
    10829,
    10961,
    10963,
    10965,
    10965,
    10967,
    10969,
    10997,
    10999,
    12043,
    12045,
    12047,
    12049,
    12051,
    12053,
    12055,
    12057,
    12059,
    12099,
    12101,
    12103,
    12105,
    12107,
    12109,
    12157,
    12159,
    12161,
    12163,
    12165,
    12167,
    12169,
    12203,
    12205,
    12207,
    12209,
    12247,
    12249,
    12277,
    12279,
    12305,
    12307,
    12309,
    12347,
    12349,
    12351,
    12353,
    12355,
    12357,
    12359,
    12435,
    12437,
    12439,
    12459,
    12487,
    12489,
    12524,
    12527,
    12529,
    12555,
    12557,
    12587,
    12683,
    13053,
    13055,
    13086,
    13088,
    13089,
    13127,
    13156,
    13158,
    13159,
    13187,
    13189,
    13347,
    13347,
    13349,
    13351,
    13353,
    13355,
    13357,
    13359,
    13403,
    13407,
    13409,
    13439,
    13465,
    13507,
    13581,
    13583,
    13585,
    13589,
    13593,
    13595,
    13597,
    13599,
    13627,
    14050,
    14052,
    14057,
    14059,
    14089,
    14129,
    14163,
    14165,
    14167,
    14169,
    14193,
    14195,
    14197,
    14199
]