
// Styling 
import "../Styling/AWDFuhrparkPage.css"

// Components 
import AWDPageNavbar from "../../Common/Components/PageNavbar/AWDPageNavbar"
import AWDPageTeaser from "../../Common/Components/PageTeaser/AWDPageTeaser"
import AWDImageCard from "../../Common/Components/ImageCard/AWDImageCard"
import AWDFuhrparkFeatureCard from "./AWDFuhrparkFeatureCard"
import AWDFuhrparkVaraintCard from "./AWDFuhrparkVaraintCard"
import AWDFuhrparkVehicleCard from "./AWDFuhrparkVehicleCard"
import AWDPageFooter from "../../Common/Components/PageFooter/AWDPageFooter"

export default function AWDFuhrparkPage() {
    return (
        <div className="AWDPageContainer">
            <AWDPageNavbar />
            <PageTeaser /> 
            <PageContent /> 
            <AWDPageFooter />
        </div>
    )
}

function PageTeaser() {
    return (
        <>
            <AWDPageTeaser
                id={"fuhrparkTeaser"}
                title={"Wir für Sie im Einsatz"}
                subTitles={["Fuhrpark", "|", "Maschinenpark", "|", "Fahrzeuge"]}
                imageSrc={"/imageRessource/alpinimages/winterdienst/fahrzeuge/fuhrparkWebTeaserImage.jpg"}
                imageAlt={"teaser fuhrpark"}
            />
        </>
    )
}

function PageContent() {
    return (
        <>
            <div className="AWDPageContent">
                <div className="fuhrparkCardGrid">

                    <AWDFuhrparkVehicleCard />

                    <AWDImageCard
                        id={"fuhrparkDetailImage"}
                        gridArea={"fuhrparkDetailImage"}
                        imageSrc={"/imageRessource/alpinimages/winterdienst/fahrzeuge/fuhrparkDetailViewImage2.jpg"}
                        altText={"fuhrparkImage"}
                    />

                    <AWDImageCard
                        id={"fuhrparkPickupImage1"}
                        gridArea={"fuhrparkPickupImage1"}
                        imageSrc={"/imageRessource/alpinimages/winterdienst/fahrzeuge/fuhrparkPickupVehicleImage.jpg"}
                        altText={"fuhrparkPickupImage1"}
                    />

                    <AWDImageCard
                        id={"fuhrparkOverviewImage"}
                        gridArea={"fuhrparkOverviewImage"}
                        imageSrc={"/imageRessource/alpinimages/winterdienst/fahrzeuge/fuhrParkOverviewImage.jpg"}
                        altText={"fuhrparkOverviewImage"}
                    />

                    <AWDImageCard
                        id={"fuhrparkSnowImage"}
                        gridArea={"fuhrparkSnowImage"}
                        imageSrc="/imageRessource/alpinimages/winterdienst/fahrzeuge/fuhrparkMobileTeaserImage.jpg"
                        altText={"fuhrparkSnowImage"}
                    />

                    <AWDImageCard
                        id={"fuhrparkSnowImage"}
                        gridArea={"fuhrparkVehicleImage"}
                        imageSrc="/imageRessource/alpinimages/winterdienst/fahrzeuge/fuhrparkVehicleImage.jpg"
                        altText={"fuhrparkSnowImage"}
                    />

                    <AWDFuhrparkFeatureCard />

                    <p className="fuhrparkVariantTitle">Räumvarianten im Winterdienst</p>

                    <AWDFuhrparkVaraintCard
                        title={"Handreinigung"}
                        description={"Die Schnee- und Glättebekämpfung von Hand bietet sich überall dort an, wo der Einsatz von Maschinen aufgrund der örtlichen Umstände nicht möglich ist, z.B. Ihr Weg von der Haustür zum Gartentor oder vom Vorderhaus zum Hinterhaus. Die Arbeitsbreite beträgt im Normalfall 100 cm. Anschließend werden die Flächen entsprechend abgestumpft."}
                        gridArea={"varaint1"}
                    />

                    <AWDFuhrparkVaraintCard
                        title={"Räumfahrzeuge mit Bürste"}
                        description={"Die wendigen Handkehrmaschinen setzen wir z. B. in kleineren bis mittleren Wohnanlagen ein. Also überall dort, wo das Befahren der Wege mit schweren Maschinen nicht möglich ist."}
                        gridArea={"varaint2"}
                    />

                    <AWDFuhrparkVaraintCard
                        title={"Räumfahrzeuge mit Schild"}
                        description={"Auf großen, breiten Flächen mit entsprechender Tragfähigkeit stehen wir mit unseren Unimogs zur Verfügung. Sie sind mit Räumschild und Streuer ausgerüstet und bieten für das Streugut eine Zuladung von 2 m³. Unterschiedlich große Maschinen bieten uns die Möglichkeit, Ihnen ein individuelles, speziell auf Ihre Bedürfnisse zugeschnittenes Angebot zu unterbreiten."}
                        gridArea={"varaint3"}
                    />
                </div>
            </div>
        </>
    )
}