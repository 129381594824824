// Styling 
import "../Styling/AWDLandschaftsbauPage.css"

// Compontens  
import AWDPageNavbar from "../../Common/Components/PageNavbar/AWDPageNavbar"
import AWDPageTeaser from "../../Common/Components/PageTeaser/AWDPageTeaser"
import AWDTextCard from "../../Common/Components/TextCard/AWDTextCard"
import AWDImageCard from "../../Common/Components/ImageCard/AWDImageCard"
import AWDImageScrollView from "../../Common/Components/ImageScrollView/AWDImageScrollView"
import AWDPageFooter from "../../Common/Components/PageFooter/AWDPageFooter"

// Data 
import { AWDLandschaftsbauImageData } from "../Data/AWDLandschaftsbauImageData"

export default function AWDLandschaftsbauPage() {
    return ( 
        <div className="AWDPageContainer">
            <AWDPageNavbar />
            <PageTeaser />
            <PageContent /> 
            <AWDPageFooter /> 
        </div>
    )
}

function PageTeaser() {
    return (
        <>
            <AWDPageTeaser
                id={"landschaftsbauTeaser"}
                title={"Ihr Garten- & Landschaftsbau in Berlin"}
                subTitles={["Gartenlandschaftsbau", "|", "Wegebau", "|", "Zaunbau", "|", "Holzbau"]}
                imageSrc={"/imageRessource/stockimages/landschaftsbau/landschaftsbauTeaserImage.jpg"}
                imageAlt={"teaser landschaftsbau"}
            />
        </>
    )
}

function PageContent() {
    return (
        <div className="AWDPageContent">
            <div className="landschaftsbauCardGrid">
                <LandschaftsbauInfo />
                <LandschaftsbauScrollView />
                <Wegebau />
                <Zaunbau />
                <Holzbau />
            </div>
        </div>
    )
}

function LandschaftsbauInfo() {
    return (
        <>
            <AWDTextCard
                title={"Garten- & Landschaftsbau"}
                subtitle={""}
                description={"Seit 1991 gestalten wir leidenschaftlich Gärten und Landschaften in Berlin. Unser Team hat den \"grünen Daumen\" für Pflanzen und Rasen, sowie Know-how in Pflaster- und Erdarbeiten. Unsere Profis beraten Sie gerne bei der individuellen Gartenplanung. Zeit für Ihre Wünsche nehmen wir uns gerne. Willkommen bei uns, Ihrem Garten-Partner."}
                bulletpoints={[]}
                gridArea="landschaftInfo"
                hasLinkButton={false}
                buttonText=""
                navLink=""
            />

            <AWDImageCard
                imageSrc={"/imageRessource/stockimages/landschaftsbau/landschaftsbauInfoImage.jpg"}
                altText={"landschaftsbauImage"}
                id={"landschaftbauInfoImage"}
                gridArea={"landschaftInfoImage"}
            />
        </>
    )
}

function LandschaftsbauScrollView() { 
    return (
        <>
            <AWDImageScrollView
                images={AWDLandschaftsbauImageData}
                gridArea={"landschaftScrollView1"}
            />
        </>
    )
}

function Wegebau() { 
    return (
        <>
            <AWDTextCard
                title={"Wegebau"}
                subtitle={"Wo ein Wille ist, ist auch ein Weg"}
                description={"Unsere Erfahrung umfasst Pflasterungen aus Naturstein, Beton, Klinker, Holz sowie wassergebundene Decken und Asphalt. Bei der Planung berücksichtigen wir sorgfältig Tragfähigkeit, Frostschutz, Entwässerung und behördliche Auflagen. So entstehen langlebige und ansprechende Flächen. Unser Ziel ist es, nicht nur optisch ansprechende Wege und Plätze zu gestalten, sondern auch ihre Funktionalität und Widerstandsfähigkeit sicherzustellen."}
                bulletpoints={[]}
                gridArea="wegebau"
                hasLinkButton={false}
                buttonText=""
                navLink=""
            />

            <AWDImageCard
                imageSrc={"/imageRessource/stockimages/landschaftsbau/landschaftsbauWegebauImage.jpg"}
                altText={"wegebauImage"}
                id={"wegebauInfoImage"}
                gridArea={"wegebauImage"}
            />
        </>
    )
}

function Zaunbau() { 
    return (
        <>
            <AWDImageCard
                imageSrc={"/imageRessource/stockimages/landschaftsbau/landschaftsbauZaunImage.jpg"}
                altText={"zaunbauImage"}
                id={"zaunbauInfoImage"}
                gridArea={"zahnbauImage"}
            />

            <AWDTextCard
                title={"Zaunbau"}
                subtitle={"Nicht nur Maschendrahtzaun"}
                description={"Wir kümmern uns gerne um die Umzäunung Ihres Grundstücks. Ob Holz, Metall, Streifenfundament oder Punktfundamente – wir haben die passende Lösung für Ihre Wünsche. Wählen Sie aus einer breiten Palette an Fertigsystemen oder entscheiden Sie sich für eine maßgeschneiderte Anfertigung. Neben umfassender Beratung garantieren wir eine professionelle Umsetzung."}
                bulletpoints={[]}
                gridArea="zaunbau"
                hasLinkButton={false}
                buttonText=""
                navLink=""
            />
        </>
    )
}

function Holzbau() { 
    return (
        <>
            <AWDTextCard
                title={"Holzbau"}
                subtitle={"Gut Holz!"}
                description={"Willkommen in unserer Welt der Holzgestaltung! Hier finden Sie eine breite Auswahl an hochwertigen Produkten wie Carports, Pergolen, Stützwände aus Bohlen oder Palisaden, Holzpflaster, Terrassendecks und Sichtschutzwände. Lassen Sie sich von den vielfältigen Möglichkeiten dieses Werkstoffs begeistern – sei es aus heimischen Nadelhölzern oder edlen Harthölzern wie Robinie oder Eiche. Wir laden Sie ein, die zeitlose Schönheit und die praktische Vielseitigkeit von Holz in all seinen Formen zu entdecken."}
                bulletpoints={[]}
                gridArea="holzbau"
                hasLinkButton={false}
                buttonText=""
                navLink=""
            />

            <AWDImageCard
                imageSrc={"/imageRessource/stockimages/landschaftsbau/landschaftsbauHolzbauImage.jpg"}
                altText={"wegebauImage"}
                id={"holzbauInfoImage"}
                gridArea={"holzbauImage"}
            />
        </>
    )
}