
import AWDPageFooter from "../../Common/Components/PageFooter/AWDPageFooter";
import AWDPageNavbar from "../../Common/Components/PageNavbar/AWDPageNavbar";

// Styling 
import "../Styling/AWDRechtGesetzPage.css"
import AWDRechtLinkCard from "./AWDRechtLinkCard";

export default function AWDRechtGesetzPage() {
    return (
        <div className="AWDPageContainer">
            <AWDPageNavbar />
            <div className="AWDBasicPageContent">
                <div className="rechtContentGrid">
                    <p className="rechtTitle">Recht und Gesetz beim Winterdienst in Berlin</p>
                    <p className="rechtSubTitle">Hier finden Sie die aktuellsten Informationen der zuständigen Behörden.</p>

                    <AWDRechtLinkCard
                        title={"Straßenreinigungsgesetz (StrReinG) Berlin"}
                        description={"Quelle: www.stadtentwicklung.berlin.de - (Nicht amtliche Fassung des Gesetzes) vom 19. Dezember 1978 (GVBl. S. 2501), das zuletzt durch Gesetz vom 7. Juli 2016 (GVBl. S. 436), geändert worden ist."}
                        gridArea={"gesetz1"}
                        navLink="https://gesetze.berlin.de/bsbe/document/jlr-StrReinGBEpELS/part/S"
                        buttonText="Zum Gesetz"
                    />

                    <AWDRechtLinkCard
                        title={"Pressemitteilung des Bundesfinanzhofes Nr. 41/14"}
                        description={"Quelle: juris.bundesfinanzhof.de - Winterdienst auf öffentlichen Gehwegen als haushaltsnahe Dienstleistung- Aufwendungen für einen Hausanschluss als steuerbegünstigte Handwerkerleistung"}
                        gridArea={"gesetz2"}
                        navLink="https://www.bundesfinanzhof.de/de/entscheidung/entscheidungen-online/detail/pdf/STRE201410126?type=1646225765"
                        buttonText="PDF laden"
                    />

                    <AWDRechtLinkCard
                        title={"Verwendung von Tausalz"}
                        description={"Quelle: www.verband-wohneigentum.de"}
                        gridArea={"gesetz3"}
                        navLink="https://www.verband-wohneigentum.de/berlin-brandenburg/mime/15382D1262956095.pdf"
                        buttonText="Information anzeigen"
                    />

                    <AWDRechtLinkCard
                        title={"Verwendung von Tausalz auf nicht öffentlichen Grund"}
                        description={"Quelle: www.verband-wohneigentum.de"}
                        gridArea={"gesetz4"}
                        navLink="https://www.verband-wohneigentum.de/berlin-brandenburg/mime/15383D1262956120.pdf"
                        buttonText="Information anzeigen"
                    />
                </div>
            </div>
            <AWDPageFooter />
        </div>
    )
}