
import "./AWDWebLinkButton.css"

export default function AWDWebLinkButton(
    props: { 
        buttonText: string
        url: string
    }
) {

    const buttonText = props.buttonText;
    const url = props.url;
   
    const handleClick = () => {
        window.location.href = url;
    };

    return (
        <button onClick={handleClick} className="webLinkButton">
            {buttonText}
        </button>
    );
}