
import AWDLinkButton from "../LinkButton/WebLink/AWDWebLinkButton"
import "./AWDTextCard.css"

export default function AWDTextCard(
    props: {
        title: string
        subtitle: string
        description: string
        bulletpoints: string[]
        gridArea: string
        hasLinkButton: boolean
        buttonText: string
        navLink: string
    }
) {

    const title = props.title;
    const subtitle = props.subtitle;
    const description = props.description
    const bulletpoints = props.bulletpoints
    const gridArea = props.gridArea;
    const hasLinkButton = props.hasLinkButton;
    const buttonText = props.buttonText;
    const navLink = props.navLink;

    return (
        <div className="AWDTextCardContainer" style={{ gridArea: gridArea }}>
            <p className="textCardTitle">{title}</p>
            {subtitle.trim() !== "" && <p className="textCardSubTitle">{subtitle}</p>}
            <p className="textCardDescription">{description}</p>
            <div className="textCardBulletPointContainer">
                {bulletpoints.map((bulletpoint, index) => (
                    <div className="textCardBulletPoint">
                        <p className="textCardBulletPointPoint">{"-"}</p>
                        <p className="textCardBulletPointText">{bulletpoint}</p>
                    </div>
                ))}
            </div>
            {hasLinkButton &&
                <AWDLinkButton
                    buttonText={buttonText}
                    url={"https://www.berlin.de/umwelt/themen/natur-pflanzen-artenschutz/artikel.163584.php"}
                />
            }
        </div>
    )
}