
import "./AWDPageTeaser.css"

export default function AWDPageTeaser(
    props: { 
        id: string
        title: string
        subTitles: string[]
        imageSrc: string
        imageAlt: string
    }
) {

    const { id, title, subTitles, imageSrc, imageAlt} = props;

    return ( 
        <div className="teaserContainer">
            <div className="teaserImageContainer">
                <img src={imageSrc} alt={imageAlt} className="teaserImage" id="teaserSmallImage"/>
            </div>
            <div className="teaserTitleContainer" id={id}>
                <p className="teaserTitleText">{title}</p>
                <div className="teaserSubTitleContainer">
                    {subTitles.map((subtitle, index) => (
                        <p className="teaserSubTitleText" id="landschaftsbauSubTitleText">{subtitle}</p>
                    ))}
                </div>
            </div>
        </div>
    )
}