
import { NavLink } from "react-router-dom"
import "./AWDPageFooter.css"
export default function AWDPageFooter() {

    return ( 
        <div className="footerContainer">
            <div className="footerGrid">
                <NavLink to="/aboutus" className="footerButton">
                    Über uns
                </NavLink>
                <NavLink to="/fuhrpark" className="footerButton">
                    Maschinenpark
                </NavLink>
                <NavLink to="/contact" className="footerButton">
                    Kontakt
                </NavLink>
                <NavLink to="/anfahrt" className="footerButton">
                    Anfahrt
                </NavLink>
                <a href="https://www.alpin-berlin.de/winterdienstreklamation/user/login.php" style={{textDecoration: 'none', alignSelf: 'center', fontSize: 16, color:'#333333', fontFamily: 'Avenir Next'}}>Winterdienstreklamation</a>
                <NavLink to="/impressum" className="footerButton">
                    Impressum
                </NavLink>
                <NavLink to="/datenschutz" className="footerButton">
                    Datenschutz
                </NavLink>
            </div>
        </div>
    )
}