
// Styling 

import "../Styling/AWDRechtLinkCard.css"

export default function AWDRechtLinkCard(
    props: { 
        title: string
        description: string
        gridArea: string
        buttonText: string
        navLink: string
    }
) {

    const title = props.title;
    const description = props.description;
    const gridArea = props.gridArea;
    const url = props.navLink;
    const buttonText = props.buttonText;

    const handleClick = () => {
        window.open(url, '_blank');
    };


    return (
        <div className="rechtLinkCardContainer" style={{ gridArea: gridArea }}>
            <div className="rechtLinkCardContent">
                <p className="rechtLinkCardTitle">{title}</p>
                <p className="rechtLinkCardDescription">{description}</p>
                <button onClick={handleClick} className="webLinkButton">
                    {buttonText}
                </button>
            </div>
        </div>
    )
}