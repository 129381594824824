
import "../CSS/AWD.css"
import "./AWDPageNavbar.css"
import { NavLink } from "react-router-dom"

export default function AWDPageNavbar() {
    
    const callNumber = () => { 
        window.location.href = "tel:+49 30 6843601"; 
    }

    const writeMail = () => { 
        window.location.href = "mailto:mail@alpin-berlin.de";
    }

    return ( 
        <div style={{paddingTop: '70px'}}>
            <div className="pageNavbar">
                <NavLink to="/">
                    <img src={"/imageRessource/alpinimages/alpin/alpinLogo.png"} alt="Alpin Winterdienst Logo" id="AWDLogoPageNavbar" />
                </NavLink>
                
                <div className="pageNavbarLeft">

                </div>

                <div className="pageNavbarRight">
                    <button className="pageNavbarPhoneButton" onClick={callNumber}>
                        <img src={"/imageRessource/stockimages/icons/phoneIcon.png"} alt="Phone Icon" className="icon" id="AWDPhoneIconPageNavbar" />
                        <p>030/684 36 01</p>
                    </button>
                    <button className="pageNavbarMailButton" onClick={writeMail}>
                        <img src={"/imageRessource/stockimages/icons/mailIcon.png"} alt="Mail Icon" className="icon" id="AWDMailIconPageNavbar" />
                        <p>mail@alpin-berlin.de</p>
                    </button>
                    <NavLink to="/angebot" className="offerRequestButton" id="pageNavbarOfferRequestButton">
                        kostenloses Angebot anfordern
                    </NavLink>
                </div>
            </div>
        </div>
    )
}