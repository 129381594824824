
// Styling 

import "../Styling/AWDFuhrparkVaraintCard.css"

export default function AWDFuhrparkVaraintCard(
    props: { 
        title: string
        description: string
        gridArea: string
    }
) {

    const title = props.title;
    const description = props.description;
    const gridArea = props.gridArea;

    return ( 
        <div className="fuhrparkVariantContainer" style={{ gridArea: gridArea}}>
            <div className="fuhrparkVariantIconContainer"></div>
            <p className="fuhrparkVaraintTitle">{title}</p>
            <p className="fuhrparkVaraintDescription">{description}</p>
        </div>
    )
}