
import { useEffect, useState } from "react"
import AWDRoundedLinkButton from "../../Common/Components/LinkButton/AWDRoundedLinkButton"
import "../Styling/AWDWinterdienstZipCodeCard.css"

import LupeIcon from "../../ImageResources/StockImages/Icons/lupeIcon.png"
import { AWDWinterdienstValidZipCodes } from "../Data/AWDPostleitzahlenData"

export default function AWDWinterdienstZipCodeCard(
    props: { 
        title: string
        subtitle: string
        image: string
        imageAlt: string
        buttonText: string
        navLink: string
    }
) {

    const title = props.title;
    const subtitle = props.subtitle;
    const image = props.image;
    const imageAlt = props.imageAlt;
    const buttonText = props.buttonText;
    const navLink = props.navLink;

    const validZipCodes: number[] = AWDWinterdienstValidZipCodes
    const normalColor: string = 'rgb(84, 171, 233)'
    const loadingColor: string = 'gray'
    const validColor: string = 'rgb(102, 185, 83)'

    const [containerColor, setContainerColor] = useState<string>(normalColor);
    const [textInputValue, setTextInputValue] = useState<string>('');
    const [savedInputValue, setSavedInputValue] = useState<string>('');
    const [isZipCodeValid, setIsZipCodeValid] = useState<boolean>(false);
    const [subTitleText, setSubTitleText] = useState<string>("Jetzt Vefügbarkeit prüfen");


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextInputValue(event.target.value);
    };

    const clearInputText = () => { 
        setTextInputValue('');
        setIsZipCodeValid(false);
        setContainerColor(normalColor)
        setSubTitleText("Jetzt Vefügbarkeit prüfen")
    }

    const handleInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSetPostalCode()
        }
    };

    const handleSetPostalCode = () => {
        setContainerColor(loadingColor)
        setSubTitleText("Wir schauen nach")
        const delay = 1000;
        setTimeout(() => {
            validateAndSetPostalCode(textInputValue)
        }, delay)
    };

    const validateAndSetPostalCode = (inputValue: string) => {
        setSavedInputValue(inputValue);
        if (validZipCodes.includes(+inputValue)) {
            setIsZipCodeValid(true);
            setSubTitleText("Wir bedienen ihr Gebiet")
            setContainerColor(validColor)
        } else {
            setIsZipCodeValid(false);
            setSubTitleText("Leider bedienen wir ihr Gebiet nicht")
            setContainerColor(normalColor)
        }
    };


    return (
        <div className="AWDWinterdienstZipCodeCardContainer">
            <div className="winterdienstZipCodeCardTitleContainer">
                <p className="winterdienstZipCodeCardTitle">{title}</p>
            </div>
            <div className="winterdienstZipCodeCardImageContainer">
                <img src={image} alt={imageAlt} className="winterdienstZipCodeCardImage" />
            </div>
            <div className="winterdienstZipCodeCardContentContainer" style={{ backgroundColor: containerColor }}>
                <p className="winterdienstZipCodeCardSubTitle">{ subTitleText }</p>
                <div className="winterdienstZipCodeCardActionContainer">
                    
                    <div className="winterdienstZipCodeCardInputContainer">
                        
                        <div className="text-input-container">
                            <input
                                className="text-input"
                                type="text"
                                value={textInputValue}
                                onChange={handleInputChange}
                                onKeyDown={handleInputKeyPress}
                                placeholder="Postleitzahl"
                            />
                            <div className="text-input-line"></div>
                            {textInputValue !== '' &&
                                <button
                                    className="text-input-clear-button"
                                    onClick={clearInputText}
                                >
                                    x
                                </button>
                            }
                        </div>

                        {!isZipCodeValid &&
                            <button
                                className="winterdienstInputActionButton"
                                onClick={handleSetPostalCode}
                            >
                                <img src={"/imageRessource/stockimages/icons/lupeIcon.png"} alt="Phone Icon" className="icon" />
                            </button>
                        }
                        {isZipCodeValid && 
                            <AWDRoundedLinkButton
                                buttonText={"Jetzt Angebot anfordern"}
                                link={"/angebot"}
                                gridArea={""}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}