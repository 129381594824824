
import "./AWDImageCard.css"

export default function AWDImageCard(
    props: { 
        id: string
        gridArea: string
        imageSrc: string
        altText: string
    }
) {

    const id = props.id;
    const gridArea = props.gridArea;
    const imageSrc = props.imageSrc;
    const altText = props.altText;

    return ( 
        <div className="AWDImageCardContainer" style={{ gridArea: gridArea }}>
            <img src={imageSrc} alt={altText} className="imageCardImage" id={id}/>
        </div>
    )
}