
/* Styling */
import "../../Common/Components/CSS/AWD.css"
import "../Styling/AWDHomePage.css"

/* Components */ 
import AWDPageNavbar from "../../Common/Components/PageNavbar/AWDPageNavbar"
import AWDPageTeaser from "../../Common/Components/PageTeaser/AWDPageTeaser"
import AWDHomeTopicCard from "./AWDHomeTopicCard"
import AWDPageFooter from "../../Common/Components/PageFooter/AWDPageFooter"

/* Data */ 
import { AWDHomeTopicCardContent } from "../Data/AWDHomeTopicCardData"

export default function AWDHomePage() {
    return ( 
        <div className="AWDPageContainer">
            <AWDPageNavbar />
            <AWDPageTeaser 
                id={"homeTeaser"} 
                title={"In Berlin seit 1982"} 
                subTitles={["Winterdienst", "|", "Garten- & Landschaftsbau"]} 
                imageSrc={"/imageRessource/stockimages/home/homeTeaserImage.jpg"} 
                imageAlt={"teaser homepage"} 
            />
            <PageContent />
            <AWDPageFooter />
        </div>
    )
}

function PageContent() {
    return (
        <div className="AWDPageContent">
            <div className="homePageCardTopics">
                {AWDHomeTopicCardContent.map((cardContent, index) => (
                    <AWDHomeTopicCard cardContent={cardContent} />
                ))}
            </div>
        </div>
    )
}