import AWDImageCard from "../../Common/Components/ImageCard/AWDImageCard";
import AWDPageFooter from "../../Common/Components/PageFooter/AWDPageFooter";
import AWDPageNavbar from "../../Common/Components/PageNavbar/AWDPageNavbar";

// Styling 
import "../Styling/AWDAboutUsPage.css"
export default function AWDAboutUsPage() {
    return ( 
        <div className="AWDPageContainer">
            <AWDPageNavbar />
            <div className="AWDBasicPageContent">
                <p className="basicPageTitle">Über uns</p>
                <p className="basicPageSubTitle">Die Alpin GmbH in Berlin existiert seit 1982</p>

                <br />
                <p className="basicPageText" style={{fontWeight: 600}}>Schneebeseitigung, Garten- und Landschaftsbau, Grünanlagenpflege sowie Baumarbeiten sind die Tätigkeitsfelder der Alpin GmbH.</p>
                <br />

                <p className="basicPageText">Herr Karsten Bruck ist im Bereich Winterdienst und Streugutbeseitigung unser Einsatzleiter.</p>
                <br />
                <p className="basicPageText">Herr Marko Krüger leitet den Bereich Garten- und Landschaftsbau, Grünanlagenpflege sowie Baumarbeiten.</p>

                <br />
                <p className="basicPageText">Unsere Fachkräfte verfügen über jahrelange Berufserfahrung und stehen Ihnen gern mit Rat und Tat zur Seite.</p>

                <div className="aboutUsImageGrid">
                    <AWDImageCard
                        id={"aboutUsImage"}
                        gridArea={"gridImage1"}
                        imageSrc={"/imageRessource/alpinimages/alpin/alpinEntryImage.jpg"}
                        altText={""}
                    />
                    <AWDImageCard
                        id={"aboutUsImage"}
                        gridArea={""}
                        imageSrc={"/imageRessource/alpinimages/alpin/alpinYardImage.jpg"}
                        altText={"gridImage2"}
                    />
                </div>
            </div>
            <AWDPageFooter /> 
        </div>
    )
}