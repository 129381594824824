// Styling 
import "../Styling/AWDWinterdienstRefCard.css"

export default function AWDWinterdienstRefCard(
    props: { 
        title: string
        description: string
        gridArea: string
    }
) {

    const title = props.title;
    const description = props.description;
    const gridArea = props.gridArea;

    return (
        <div className="rechtLinkCardContainer" style={{ gridArea: gridArea }}>
            <div className="rechtLinkCardContent">
                <p className="rechtLinkCardTitle">{title}</p>
                <p className="rechtLinkCardDescription">{description}</p>
            </div>
        </div>
    )
}