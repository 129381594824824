
// Styling 
import AWDRoundedLinkButton from "../LinkButton/AWDRoundedLinkButton"
import "./AWDImageLinkCard.css"

export default function AWDImageLinkCard(
    props: { 
        title: string
        subtitle: string
        image: string
        imageAlt: string
        buttonText: string
        navLink: string
        gridArea: string
        isExternLink: boolean|undefined
    }
) {

    const title = props.title;
    const subtitle = props.subtitle;
    const image = props.image;
    const imageAlt = props.imageAlt;
    const buttonText = props.buttonText;
    const navLink = props.navLink;
    const gridArea = props.gridArea;
    const isExternLink = props.isExternLink;

    return (
        <div className="AWDImageLinkCardContainer" style={{ gridArea: gridArea}}>
            <div className="imageLinkCardTitleContainer">
                <p className="imageLinkCardTitle">{title}</p>
            </div>
            <div className="imageLinkCardImageContainer">
                <img src={image} alt={imageAlt} className="imageLinkCardImage" />
            </div>
            <div className="imageLinkCardContentContainer">
                <p className="imageLinkCardSubTitle">{subtitle}</p>
                
                {isExternLink ? (
                    <div>
                        <a href="https://www.alpin-berlin.de/winterdienstreklamation/user/login.php"
                        className="imageLinkCardExternLinkButton"
                           >
                            Winterdienstreklamation
                        </a>
                    </div>
                ) : (
                    <AWDRoundedLinkButton
                        buttonText={buttonText}
                        link={navLink}
                        gridArea={""} 
                    />
                )}
            </div>
        </div>
    )
}