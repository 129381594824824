
import AWDPageFooter from "../../Common/Components/PageFooter/AWDPageFooter";
import AWDPageNavbar from "../../Common/Components/PageNavbar/AWDPageNavbar";
import AWDTextContainer from "../../Contact/Components/AWDTextContainer";
import AWDTextLinkContainer from "../../Contact/Components/AWDTextLinkContainer";

// Styling 
import "../Styling/AWDImpressumPage.css"

export default function AWDImpressumPage() {
    return (
        <div className="AWDPageContainer">
            <AWDPageNavbar />
            <div className="AWDBasicPageContent">
                <p className="basicPageTitle">Impressum</p>
                <Anschrift />
                <Contact />
                <Legal />
                <Person />
                <Haftung />
                <Urheberrecht />
                <Recht />
                <Copy />
            </div>
            <AWDPageFooter />
        </div>
    )
}

function Anschrift() {
    return (
        <>
            <br />
            <p className="basicPageText" style={{ fontWeight: 600 }}>Anschrift</p>
            <p className="basicPageText">Alpin GmbH</p>
            <p className="basicPageText">Späthstraße 21-23</p>
            <p className="basicPageText">12359 Berlin</p>
        </>
    )
}

function Contact() {
    return (
        <>
            <br />
            <AWDTextContainer title={"Telefon:"} text={"030 6843601"}></AWDTextContainer>
            <AWDTextContainer title={"Fax:"} text={"030 6843610"}></AWDTextContainer>
            <AWDTextLinkContainer title={"E-Mail:"} />
        </>
    )
}

function Legal() { 
    return (
        <>
            <br />
            <p className="basicPageText">Steuer-Nr: 29/205/30666 (Finanzamt für Körperschaften III in Berlin)</p>
            <AWDTextContainer title={"Handelsregistereintrag:"} text={"122201 B"}></AWDTextContainer>
            <AWDTextContainer title={"Gerichtsstand:"} text={"Berlin - Charlottenburg"}></AWDTextContainer>
        </>
    )
}

function Person() { 
    return (
        <>
            <br />
            <AWDTextContainer title={"Geschäftsführer:"} text={"Wolfgang Weiß"}></AWDTextContainer>
        </>
    )
}

function Haftung() { 
    return (
        <>
            <br />
            <p className="basicPageText" style={{ fontWeight: 600 }}>Haftungsausschluss</p>
            <p className="basicPageText">Wir übernehmen keinerlei Gewähr für Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche, die sich auf Schäden materieller oder immaterieller Art beziehen, welche durch Nutzung oder Nichtnutzung der angebotenen Informationen bzw. durch Nutzung fehlerhafter oder unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, es sei denn, dem Autor ist ein vorsätzliches oder grob fahrlässiges Verschulden nachzuweisen. Alle Informationsangebote sind freibleibend und unverbindlich. Das Entfernen von Teilen oder Löschen von Veröffentlichungen kann ohne besondere Ankündigung zeitweise oder endgültig erfolgen. Sofern Links direkt oder indirekt auf Ziele verweisen, die außerhalb unseres Verantwortungsbereichs liegen, distanzieren wir uns grundsätzlich von Inhalten, die gesetzeswidrig sind oder den guten Sitten widersprechen. Für Schäden, die aus der Nutzung solcher Fremdangebote entstehen, lehnen wir grundsätzlich jegliche Haftung ab.</p>
        </>
    )
}

function Urheberrecht() { 
    return (
        <>
            <br />
            <p className="basicPageText" style={{ fontWeight: 600 }}>Anschrift</p>
            <p className="basicPageText">Wir sind bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken und Texte zu beachten, von uns selbst erstellte Grafiken, Sounds und Texte zu nutzen oder auf lizenzfreie Grafiken, Sounds und Texte zurückzugreifen. Sollte sich auf den jeweiligen Seiten dennoch eine ungekennzeichnete, aber durch fremdes Copyright geschützte Grafik oder Text befinden, so konnte das Copyright von uns nicht festgestellt werden. Im Falle einer solchen unbeabsichtigten Copyrightverletzung werden wir das entsprechende Objekt nach Benachrichtigung aus unserer Publikation entfernen bzw. mit dem entsprechenden Copyright kenntlich machen.</p>
        </>
    )
}

function Recht() { 
    return (
        <>
            <br />
            <p className="basicPageText" style={{ fontWeight: 600 }}>Rechtswirksamkeit dieses Haftungsausschlusses</p>
            <p className="basicPageText">Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.</p>
        </>
    )
}

function Copy() { 
    return (
        <>
            <br />
            <p className="basicPageText">Grafiken & Bilder unter anderem von:</p>
            <br />
            <p className="basicPageText">© Peggy Heisterkamp - Alpin GmbH Berlin</p>
            <p className="basicPageText">© lesniewski - Fotolia.com</p>
            <p className="basicPageText">© kobra78 - Fotolia.com</p>
            <p className="basicPageText">© cory - Fotolia.com</p>
            <p className="basicPageText">© Smileus - Fotolia.com</p>
            <p className="basicPageText">© Krawczyk-Foto - Fotolia.com</p>
            <p className="basicPageText">© Pefkos - Fotolia.com</p>
            <p className="basicPageText">© Jürgen Fälchle - Fotolia.com</p>
        </>
    )
}