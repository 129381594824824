
// Styling 
import "../Styling/AWDWinterdienstInfoCard.css"

export default function AWDWinterdienstInfoCard() {
    return ( 
        <div className="winterdienstInfoCardContainer" id="wdInfoCard">
            <img src={"/imageRessource/alpinimages/alpin/alpinLogo.png"} alt="Alpin Winterdienst Logo" id="AWDLogoWinterdienstInfoCard" />
            <p className="winterdienstInfoCardText">
                Die Alpin Schneebeseitigungs GmbH betreut seit 1982 den Bereich Winterdienst 
                in Berlin. Die jahrelange Erfahrung im Winterdienst und unser vielseitiger Maschinenpark 
                ermöglicht es uns, unser Einsatzgebiet gewissenhaft zu betreuen.
            </p>
        </div>
    )
}