// Styling 
import "../Styling/AWDBaumarbeitenPage.css"

// Components 
import AWDPageNavbar from "../../Common/Components/PageNavbar/AWDPageNavbar"
import AWDPageTeaser from "../../Common/Components/PageTeaser/AWDPageTeaser"
import AWDTextCard from "../../Common/Components/TextCard/AWDTextCard"
import AWDImageCard from "../../Common/Components/ImageCard/AWDImageCard"
import AWDPageFooter from "../../Common/Components/PageFooter/AWDPageFooter"

export default function AWDBaumarbeitenPage() {
    return (
        <div className="AWDPageContainer">
            <AWDPageNavbar />
            <PageTeaser /> 
            <PageContent />
            <AWDPageFooter />
        </div>
    )
}

function PageTeaser() {
    return (
        <>
            <AWDPageTeaser
                id={"baumarbeitenTeaser"}
                title={"Baumarbeiten in Berlin"}
                subTitles={["Baumarbeiten", "|", "Baumbeschnitt", "|","Fällungen"]}
                imageSrc={"/imageRessource/stockimages/baumarbeiten/baumarbeitenTeaserImage.jpg"}
                imageAlt={"teaser baumarbeiten"}
            />
        </>
    )
}

function PageContent() {
    return (
        <>
            <div className="AWDPageContent">
                <div className="baumarbeitenCardGrid">
                    <AWDTextCard
                        title={"Baumarbeiten"}
                        subtitle={"Mein Freund der Baum"}
                        description={"Ihre Bäume verdienen beste Pflege. Als Immobilienbesitzer oder Wohnanlagenverwalter sind Sie dafür verantwortlich, einen vitalen und sicheren Baumbestand zu bewahren. Wir begleiten Sie bei behördlichen Genehmigungen, professionellen Baumdiagnosen, Baumpflege und -fällungen. Unsere erfahrenen Baumexperten führen Arbeiten jeglicher Komplexität durch – unterstützt von modernen Kletter- und Hubtechniken. Unsere Arbeit folgt den aktuellsten ZTV-Baumschutzrichtlinien und Naturschutzgesetzen."}
                        bulletpoints={[]}
                        gridArea="baumInfo"
                        hasLinkButton={false}
                        buttonText=""
                        navLink=""
                    />
                    <AWDImageCard
                        imageSrc={"/imageRessource/stockimages/baumarbeiten/baumarbeitenImage1.jpg"}
                        altText={"landschaftsbauImage"}
                        id={"baumarbeitenDetailImage"}
                        gridArea={"baumInfoImage"}
                    />

                    <AWDImageCard
                        imageSrc={"/imageRessource/alpinimages/baumarbeiten/baumarbeitenImage1.png"}
                        altText={"landschaftsbauImage"}
                        id={"baumarbeitenOldImage"}
                        gridArea={"baumFeatureImage"}
                    />
                    <AWDTextCard
                        title={"Leistungsprofil"}
                        subtitle={""}
                        description={"Unser Leistungsprofil umfasst folgende Gebiete:"}
                        bulletpoints={[
                            "Visuelle Baumschadendiagnose nach VTA (Visual Tree Assessment) und erstellen von Baumkatastern",
                            "Übernahme von Formalitäten mit den zuständigen Grünflächenämtern und Einholen von Genehmigungen",
                            "Kronenpflege",
                            "Fassadenfreischnitte",
                            "Beseitigung von Totholz (Verkehrssicherungspflicht)",
                            "Erstellen von Lichtraumprofilen über Gehwegen, Fahrbahnen",
                            "Beseitigung von Baumschäden durch Baumaßnahmen",
                            "Sturmschadenbeseitigung",
                            "Baumfällung",
                            "Baugrundstücksrodung",
                            "Wurzelstockrodung",
                            "Stubbenfräsen",
                            "Ersatzpflanzungen"
                        ]}
                        gridArea="baumFeature"
                        hasLinkButton={false}
                        buttonText=""
                        navLink=""
                    />

                    <AWDTextCard
                        title={"Recht & Gesetz"}
                        subtitle={""}
                        description={"Gemäß dem Berliner Naturschutzgesetz (NatSchGBln) ist das Schneiden, Fällen oder Entfernen von Bäumen, Gebüsch oder Ufervegetation vom 01. März bis 30. September untersagt, auch in Privatgärten. Dies schützt brütende Vögel und Tiere. Bei unmittelbarer Gefahr durch Sturm darf der Eigentümer schnell handeln und eine Firma oder die Feuerwehr mit der Fällung beauftragen. Hierbei ist eine Dokumentation des Baumzustands mittels Fotos erforderlich."}
                        bulletpoints={[]}
                        gridArea="baumRecht"
                        hasLinkButton={true}
                        buttonText="Zum Gesetz"
                        navLink="https://www.berlin.de/umwelt/themen/natur-pflanzen-artenschutz/artikel.163584.php"
                    />
                </div>
            </div>
        </>
    )
}