
import "./AWDImageScrollView.css"


import AWDImageCard from "../ImageCard/AWDImageCard";
import { useState } from "react";

export default function AWDImageScrollView(
    props: { 
        images: string[]
        gridArea: string
    }
) {

    const images = props.images;
    const gridArea = props.gridArea;

    const [scrollX, setScrollX] = useState(0);

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        setScrollX(event.currentTarget.scrollLeft);
    };


    return ( 
        <div className="scrollviewContainer" style={{gridArea: gridArea}}>
            <div className="scrollview" onScroll={handleScroll}>
                {images.map((imageUrl, index) => (
                    <div className="image-container">
                        <img src={imageUrl} alt={""} className="imageCardImage" id="scrollViewImage" />
                    </div>
                ))}
            </div>
        </div>
    )
}