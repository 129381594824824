
// Styling 
import "../Styling/AWDGruenanlagenPage.css"

// Components  
import AWDPageNavbar from "../../Common/Components/PageNavbar/AWDPageNavbar"
import AWDPageTeaser from "../../Common/Components/PageTeaser/AWDPageTeaser"
import AWDTextCard from "../../Common/Components/TextCard/AWDTextCard"
import AWDImageCard from "../../Common/Components/ImageCard/AWDImageCard"
import AWDImageScrollView from "../../Common/Components/ImageScrollView/AWDImageScrollView"
import AWDPageFooter from "../../Common/Components/PageFooter/AWDPageFooter"

// Data 
import { AWDGruenanlagenpflegeImageData } from "../Data/AWDGruenanlagenpflegeImageData"

export default function AWDGruenanlagenPage() {
    return ( 
        <div className="AWDPageContainer">
            <AWDPageNavbar />
            <PageTeaser /> 
            <PageContent />
            <AWDPageFooter /> 
        </div>
    )
}

function PageTeaser() {
    return (
        <>
            <AWDPageTeaser
                id={"gruenanlagenTeaser"}
                title={"Grünanlagenpflege in Berlin"}
                subTitles={["Grünanlagen", "| Rasenpflege", "| Heckenschnitt"]}
                imageSrc={"/imageRessource/stockimages/gruenanlagen/gruenanlagenpflegeTeaserImage.jpg"}
                imageAlt={"teaser gruenanlagen"}
            />
        </>
    )
}

function PageContent() {
    return (
        <>
            <div className="AWDPageContent">
                <div className="gruenanlagenCardGrid">
                    <GruenanlagenInfo />
                    <GruenanlagenScrollView />
                    <GruenanlagenSpezial />
                    <GruenanlagenFeature />
                </div>
            </div>
        </>
    )
}

function GruenanlagenInfo() {
    return (
        <>
            <AWDTextCard
                title={"Grünanlagenpflege"}
                subtitle={"Es grünt so grün..."}
                description={"Die Pflege von Bäumen, Pflanz- und Rasenflächen durch unser Fachpersonal hilft bei der Erhaltung Ihrer Grünanlage."}
                bulletpoints={[]}
                gridArea="gruenanlagenInfo"
                hasLinkButton={false}
                buttonText=""
                navLink=""
            />
            <AWDImageCard
                imageSrc={"/imageRessource/stockimages/gruenanlagen/gruenanlagenPlantImage.jpg"}
                altText={"landschaftsbauImage"}
                id={"gruenanlagenInfoImage"}
                gridArea={"gruenanlagenInfoImage"}
            />
        </>
    )
}

function GruenanlagenScrollView() {
    return (
        <>
            <AWDImageScrollView
                images={AWDGruenanlagenpflegeImageData}
                gridArea={"gruenanlagenScrollview"}
            />
        </>
    )
}

function GruenanlagenSpezial() {
    return (
        <>
            <AWDTextCard
                title={"Grüner Daumen"}
                subtitle={""}
                description={"Unser \"grüner Daumen\" ist bereit für Ihr Projekt. Von Grundpflege bis zur Expertenberatung über Düngung und Umpflanzung – wir passen uns Ihren Bedürfnissen an. Entdecken Sie eine individuelle Betreuung, die über das Entfernen von Wildwuchs hinausgeht, inklusive Fertigstellung und Entwicklungspflege."}
                bulletpoints={[]}
                gridArea="gruenanlagenSpezial"
                hasLinkButton={false}
                buttonText=""
                navLink=""
            />
            <AWDImageCard
                imageSrc={"/imageRessource/stockimages/gruenanlagen/gruenanalgenCareImage.jpg"}
                altText={"landschaftsbauImage"}
                id={"gruenanlagenSpezialImage"}
                gridArea={"gruenanlagenSpezialImage"}
            />
        </>
    )
}

function GruenanlagenFeature() { 
    return (
        <>
            <AWDImageCard
                imageSrc={"/imageRessource/stockimages/gruenanlagen/gruenanlagenDetailImage.jpg"}
                altText={"landschaftsbauImage"}
                id={"gurenanlagenFeatureImage"}
                gridArea={"gurenanlagenFeatureImage"}
            />
            <AWDTextCard
                title={"Leistungsprofil Grünanlagenpflege"}
                subtitle={""}
                description={""}
                bulletpoints={[
                    "Rasen mähen",
                    "Rasenkanten stechen",
                    "Wildkrautbeseitigung",
                    "Entfernung von Wildwuchs auf Grauflächen",
                    "Reinigen von Wegen und Plätzen",
                    "Heckenschnitt",
                    "Bodenlockerung",
                    "Laubbeseitigung"
                ]}
                gridArea="gurenanlagenFeature"
                hasLinkButton={false}
                buttonText=""
                navLink=""
            />
        </>
    )
}