import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom'
import AWDHomePage from './HomePage/Components/AWDHomePage';
import AWDWinterdienstPage from './Winterdienst/Components/AWDWinterdienstPage';
import AWDAngebotPage from './OfferRequest/Components/AWDAngebotPage';
import AWDLandschaftsbauPage from './Landschaftsbau/Components/AWDLandschaftsbauPage';
import AWDBaumarbeitenPage from './Baumarbeiten/Components/AWDBaumarbeitenPage';
import AWDGruenanlagenPage from './Gruenanlagenpflege/Components/AWDGruenanlagenPage';
import AWDFuhrparkPage from './Fuhrpark/Components/AWDFuhrparkPage';
import AWDRechtGesetzPage from './RechtGesetz/Components/AWDRechtGesetzPage';
import AWDWinterdienstRefPage from './WinterdienstRef/Components/AWDWinterdienstRefPage';
import AWDAboutUsPage from './AboutUs/Components/AWDAboutUsPage';
import AWDContactPage from './Contact/Components/AWDContactPage';
import AWDAnfahrtPage from './Anfahrt/Components/AWDAnfahrtPage';
import AWDImpressumPage from './Impressum/Components/AWDImpressumPage';
import AWDDatenschutzPage from './Datenschutz/Components/AWDDatenschutzPage';

function ScrollToTopOnNavigation() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div>
      <ScrollToTopOnNavigation />
      <Routes>
        <Route path='/' element={<AWDHomePage />} />
        <Route path='/winterdienst' element={<AWDWinterdienstPage />} />
        <Route path='/winterdienst/referenzen' element={<AWDWinterdienstRefPage />} />
        <Route path='/landschaftsbau' element={<AWDLandschaftsbauPage />} />
        <Route path='/baumarbeiten' element={<AWDBaumarbeitenPage />} />
        <Route path='/gruenanlagen' element={<AWDGruenanlagenPage />} />
        <Route path='/angebot' element={<AWDAngebotPage />} /> 
        <Route path='/fuhrpark' element={<AWDFuhrparkPage />} />
        <Route path='/rechtgesetz' element={<AWDRechtGesetzPage />} />
        <Route path='/aboutus' element={<AWDAboutUsPage />} />
        <Route path='/contact' element={<AWDContactPage />} />
        <Route path='/anfahrt' element={<AWDAnfahrtPage />} />
        <Route path='/impressum' element={<AWDImpressumPage />} />
        <Route path='/datenschutz' element={<AWDDatenschutzPage />} />
      </Routes>
    </div>
  );
}

export default App;
