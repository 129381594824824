import { useState } from "react";

import "../Styling/AWDAngebotTextInput.css"

export default function AWDAngebotTextInput(
    props: { 
        id: string
        name: string
        placeholder: string
        gridArea: string
    }
) {

    const id = props.id;
    const name = props.name; 
    const placeholder = props.placeholder;
    const gridArea = props.gridArea;

    const [textInputValue, setTextInputValue] = useState<string>('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextInputValue(event.target.value);
    };

    const clearInputText = () => { 
        setTextInputValue('');
    }

    return ( 
        <div className="angebotTextInputContainer" style={{gridArea: gridArea}}>
            <input
                id={id}
                name={name}
                className="angebotTextInput"
                type="text"
                value={textInputValue}
                onChange={handleInputChange}
                placeholder={placeholder}
            />
            <div className="text-input-line"></div>
            {textInputValue !== '' &&
                <button
                    className="angebotClearTextButton"
                    onClick={clearInputText}
                >
                    x
                </button>
            }
        </div>
    )
}