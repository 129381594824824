
// Components
import AWDMap from "../../Common/Components/Map/AWDMap";
import AWDPageFooter from "../../Common/Components/PageFooter/AWDPageFooter";
import AWDPageNavbar from "../../Common/Components/PageNavbar/AWDPageNavbar";
import AWDAngebotInputFormular from "../../OfferRequest/Components/AWDAngebotInputFormular";

// Styling 
import "../Styling/AWDAnfahrtPage.css"

export default function AWDAnfahrtPage() {
    return ( 
        <div className="AWDPageContainer">
            <AWDPageNavbar />
            <div className="AWDBasicPageContent">
                <p className="basicPageTitle">Anfahrt</p>
                <p className="basicPageSubTitle">So finden Sie zur Alpin GmbH:</p>

                <br />
                <p className="basicPageText" style={{fontWeight: 600}}>Anschrift</p>

                <p className="basicPageText">Alpin GmbH</p>
                <p className="basicPageText">Späthstraße 21-23</p>
                <p className="basicPageText">12359 Berlin</p>
            </div>
            <AWDPageFooter /> 
        </div>
    )
}