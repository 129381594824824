
import "../Styling/AWDFuhrparkVehicleCard.css"

export default function AWDFuhrparkVehicleCard(
    props: { 
    }
) {

    const title = "Unsere Flotte";
    const subtitle = "Für jede Aufgabe gewachsen";
    const description = "Die Alpin GmbH in Berlin ist mit modernen Maschinen und Fahrzeugen ausgerüstet, die alle Bereiche abdecken.";
    const vehicles = [
        ["74", "Schneeräumfahrzeuge"],
        ["37", "Pritschen, Pickups, Kastenwagen"],
        ["9", "Handkehrmaschinen"],
        ["3", "Kontrollfahrzeuge Winterdienst"],
        ["3", "Kehrsaugmaschinen für die Streugutbeseitigung"],
        ["3", "Großflächen-Rasenmäher"],
        ["2", "Werkstatt Einsatzfahrzeuge"],
        ["2", "Radlader"],
        ["1", "Teleskoplader"],
        ["1", "Minibagger"],
        ["1", "Holzhäcksler"]
    ]

    return ( 
        <div className="fuhrparkVehicleContainer">
            <p className="fuhrparkVehicleTitle">{title}</p>
            <p className="fuhrparkVehicleSubTitle">{subtitle}</p>
            <p className="fuhrparkVehicleDescription">{description}</p>
            <div className="fuhrparkVehicleCardBulletPointContainer">
                {vehicles.map((bulletpoint, index) => (
                    <div className="fuhrParkBulletPointTextContainer">
                        <p className="fuhrparkVehicleCardBulletPointTitle">{bulletpoint[0]}</p>
                        <p className="fuhrparkVehicleCardBulletPointText">{bulletpoint[1]}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}