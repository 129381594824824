
import { AWDHomeTopicCardResource } from "../Entity/AWDHomeTopicCardResource";

export const AWDHomeTopicCardContent: AWDHomeTopicCardResource[] = [
    {
        imageSrc: "/imageRessource/alpinimages/winterdienst/winterdienstHomeTeaserImage.jpeg",
        altText: "Winterdienst Teaser Image",
        title: "WINTERDIENST",
        subtitles: ["Schneebeseitigung", "-", "Streugutbeseitigung"],
        description: "Die Alpin Schneebeseitigungs GmbH betreut seit 1982 den Bereich Winterdienst in Berlin. Die jahrelange Erfahrung im Winterdienst und unser vielseitiger Maschinenpark ermöglicht es uns, unser Einsatzgebiet gewissenhaft zu betreuen.",
        buttonText: "ZUM WINTERDIENST",
        buttonLink: '/winterdienst',
        isTopic: true
    },
    {
        imageSrc: "/imageRessource/stockimages/landschaftsbau/landschaftsbauTeaserImage.jpg",
        altText: "Landschaftsbau Teaser Image",
        title: "GARTEN- & LANDSCHAFTSBAU",
        subtitles: ["Wegebau", "-", "Zaunbau", "-", "Holzbau"],
        description: "Seit 1991 sind wir im Bereichen des Garten- und Landschaftsbaus tätig. Durch die lange Erfahrung unserer Mitarbeiter ist jeder Auftrag realisierbar. Wir stellen Ihnen gerne ausgebildete Gartenlandschaftsbauer zur Seite die Ihnen helfen Ihre Träume zu verwirklichen.",
        buttonText: "ZUM LANDSCHAFTSBAU",
        buttonLink: '/landschaftsbau',
        isTopic: true
    },
    {
        imageSrc: "/imageRessource/stockimages/gruenanlagen/gruenanlagenpflegeTeaserImage.jpg",
        altText: "Grünanlagenpflege Teaser Image",
        title: "GRÜNANLAGEN",
        subtitles: ["Mäharbeiten", "-", "Grünpflege", "-", "Laubbeseitigung"],
        description: "Die Pflege von Bäumen, Pflanz- und Rasenflächen durch unser Fachpersonal hilft bei der Erhaltung Ihrer Grünanlage. Wir beraten Sie auch gerne zu zusätzlichen Mähmaßnahmen, Düngung und Umpflanzung für eine optimale Entwicklung.",
        buttonText: "ZUR GRÜNANLAGENPFLEGE",
        buttonLink: '/gruenanlagen',
        isTopic: true
    },
    {
        imageSrc: "/imageRessource/stockimages/baumarbeiten/baumarbeitenTeaserImage.jpg",
        altText: "Baumarbeiten Teaser Image",
        title: "BAUMARBEITEN",
        subtitles: ["Fällung", "-", "Baumschnitt", "-", "Sicherheitsberatung"],
        description: "Bäume stellen ein kostbares Gut dar, deshalb sind Sie als Eigentümer oder Verwalter von Wohnanlagen in der Verantwortung einen vitalen und vor allem verkehrssicheren Baumbestand innerhalb Ihrer Grünanlage zu erhalten.",
        buttonText: "ZU BAUMARBEITEN",
        buttonLink: '/baumarbeiten',
        isTopic: true
    },
    {
        imageSrc: "/imageRessource/alpinimages/angebot/angebotAngebotImage1.jpg",
        altText: "Alternative Text für Bild 1",
        title: "KOSTENLOSE BERATUNG",
        subtitles: ["Beratung", "-", "Auskunft"],
        description: "Unser ausgebildetes Fachpersonal berät Sie gerne bei jedem Ihrer Projekte und versucht, Ihre Ideen bestmöglich umzusetzen. Wir erstellen Ihnen unverbindliche Konzepte, um diese zu erreichen.",
        buttonText: "JETZT BERATEN",
        buttonLink: '/angebot',
        isTopic: false
    }
];
