
import { AWDWinterdienstSelectOptionResource } from "../Entity/AWDWinterdienstSelectOptionResource";

import AWDVehiclePrivatkundenImage from "../../ImageResources/AlpinImages/Fuhrpark/fuhrparkVehicleImage.jpg"
import AWDVehicleVerwaltungImage from "../../ImageResources/AlpinImages/Fuhrpark/fuhrparkVehicleDetailWithPersonImage.jpg"
import AWDVehicleGewerbeImage from "../../ImageResources/AlpinImages/Fuhrpark/fuhrParkOverviewImage.jpg"

export enum AWDWinterdienstSelectOption { 
    Privatkunde = "Privatkunde",
    Verwaltung = "Verwaltung",
    Industrie = "Industrie",
    Gewerbe = "Gewerbe"
}

export function getSelectOptionData(
    forOption: AWDWinterdienstSelectOption
):  AWDWinterdienstSelectOptionResource { 
    switch (forOption) {
        case AWDWinterdienstSelectOption.Privatkunde:
            return {
                imageSrc: "/imageRessource/alpinimages/winterdienst/fahrzeuge/fuhrparkVehicleImage.jpg",
                altText: "",
                title: "Privatkunden",
                description: "Bereiten Sie sich optimal auf den Winter vor. Unser professioneller Winterdienst sorgt für schneefreie Wege und sichere Einfahrten. Nutzen Sie unser maßgeschneidertes Angebot für eine entspannte Wintersaison. Kontaktieren Sie uns jetzt und erleben Sie einen stressfreien Winter.",
                buttonText: "Mehr erfahren",
                buttonLink: "/angebot",
                isExpanded: false
            }
        case AWDWinterdienstSelectOption.Verwaltung:
            return {
                imageSrc: "/imageRessource/alpinimages/winterdienst/fahrzeuge/fuhrparkVehicleDetailWithPersonImage.jpg",
                altText: "",
                title: "Verwaltung",
                description: "Verlassen Sie sich auf unsere Expertise, um Ihre Grundstücke und Gehwege während der kalten Monate sicher zu halten. Unser erfahrenes Team bietet maßgeschneiderte Winterdienstleistungen, die den Anforderungen von Verwaltungskunden gerecht werden. Wir kümmern uns um Schnee- und Eisbeseitigung, damit Sie sich auf Ihr Kerngeschäft konzentrieren können. Kontaktieren Sie uns noch heute, um ein individuelles Angebot zu erhalten und den Winter ohne Sorgen zu erleben. Ihr verlässlicher Partner für effizienten Winterdienst in Berlin.",
                buttonText: "Mehr erfahren",
                buttonLink: "/angebot",
                isExpanded: false
            }
        case AWDWinterdienstSelectOption.Industrie:
            return {
                imageSrc: "/imageRessource/alpinimages/winterdienst/fahrzeuge/fuhrparkMobileTeaserImage.jpg",
                altText: "",
                title: "Industurie",
                description: "Verlassen Sie sich auf unser erfahrenes Team, um Ihre Industrieflächen sicher und zugänglich zu halten, selbst während der härtesten Winterbedingungen. Unser spezialisiertes Angebot im Winterdienst für Industriekunden garantiert Schnee- und Eisbeseitigung, sodass Ihr Betrieb reibungslos weiterlaufen kann. Konzentrieren Sie sich auf Ihre Produktion, während wir uns um Ihre Sicherheit kümmern. Kontaktieren Sie uns noch heute für ein individuelles Angebot und erleben Sie einen störungsfreien Winter. Ihr zuverlässiger Partner für Winterdienst in der Industrie.",
                buttonText: "Mehr erfahren",
                buttonLink: "/angebot",
                isExpanded: false
            }
        case AWDWinterdienstSelectOption.Gewerbe:
            return {
                imageSrc: "/imageRessource/alpinimages/winterdienst/fahrzeuge/fuhrParkOverviewImage.jpg",
                altText: "",
                title: "Gewerbe",
                description: "Verlassen Sie sich auf unser engagiertes Team, um Ihre gewerblichen Flächen im Winter sicher und zugänglich zu halten. Unser maßgeschneiderter Winterdienst für Gewerbekunden in Berlin bietet Schneeräumung und Streuplanung, damit Ihr Geschäft reibungslos läuft. Konzentrieren Sie sich auf Ihre Kunden, während wir die Winterarbeit erledigen. Kontaktieren Sie uns noch heute für ein individuelles Angebot und erleben Sie einen sorgenfreien Winter. Ihr zuverlässiger Partner für Winterdienst im gewerblichen Bereich.",
                buttonText: "Mehr erfahren",
                buttonLink: "/angebot",
                isExpanded: false
            }
    }
}