
// Styling 
import "../Styling/AWDWinterdienstPage.css"

// Components 
import AWDPageNavbar from "../../Common/Components/PageNavbar/AWDPageNavbar";
import AWDPageTeaser from "../../Common/Components/PageTeaser/AWDPageTeaser";
import AWDWinterdienstInfoCard from "./AWDWinterdienstInfoCard";
import AWDImageCard from "../../Common/Components/ImageCard/AWDImageCard"
import AWDWinterdienstSelectCard from "./AWDWinterdienstSelectCard";
import AWDImageLinkCard from "../../Common/Components/ImageLinkCard/AWDImageLinkCard";
import AWDWinterdienstZipCodeCard from "./AWDWinterdienstZipCodeCard";
import AWDPageFooter from "../../Common/Components/PageFooter/AWDPageFooter";

export default function AWDWinterdienstPage() {
    return (
        <div className="AWDPageContainer">
            <AWDPageNavbar />
            <PageTaeser />
            <PageContent /> 
            <AWDPageFooter />
        </div>
    )
}

function PageTaeser() {
    return (
        <>
            <AWDPageTeaser
                id={"winterdienstTeaser"}
                title={"Ihr Winterdienst in Berlin"}
                subTitles={["Winterdienst", "|", "Streuarbeiten", "|", "Eisbeseitigung"]}
                imageSrc={"/imageRessource/alpinimages/winterdienst/fahrzeuge/fuhrparkWebTeaserImage.jpg"}
                imageAlt={"teaser winterdienst"}
            />
        </>
    )
}

function PageContent() {
    return (
        <>
            <div className="AWDPageContent">
                <div className="winterdienstCardGrid">
                    <AWDWinterdienstInfoCard />
                    <AWDImageCard imageSrc={"/imageRessource/alpinimages/winterdienst/winterdienstHomeTeaserImage.jpeg"} altText={"Image"} id={"wdImageCard"} gridArea={"wdImageCard"} />
                    <AWDWinterdienstSelectCard />

                    <AWDWinterdienstZipCodeCard
                        title={"Unser Einsatzgebiet"}
                        subtitle={"Jetzt verfügbarkeit prüfen"}
                        image={"/imageRessource/alpinimages/winterdienst/winterdienstAreaImage.png"}
                        imageAlt={""}
                        buttonText={"Angebot anfordern"}
                        navLink={""}
                    />
                    <AWDImageLinkCard
                        title={"Maschinenpark"}
                        subtitle={"Von ganz klein bis ganz groß"}
                        image={"/imageRessource/alpinimages/winterdienst/fahrzeuge/fuhrparkDetailViewImage1.jpg"}
                        imageAlt={""}
                        buttonText={"Zu den Fahrzeugen"}
                        navLink={"/fuhrpark"}
                        gridArea={"wdFahrzeugeCard"}
                        isExternLink={false}
                    />
                    <AWDImageLinkCard
                        title={"Recht & Gesetz"}
                        subtitle={"Recht und Gesetz beim Winterdienst"}
                        image={"/imageRessource/alpinimages/angebot/angebotMapImage.jpg"}
                        imageAlt={""}
                        buttonText={"Erkläre mir mehr"}
                        navLink={"/rechtgesetz"}
                        gridArea={"wdRechtCard"}
                        isExternLink={false}
                    />
                    <AWDImageLinkCard
                        title={"Referenzen"}
                        subtitle={"Wir betreuen Weltkonzerne"}
                        image={"/imageRessource/alpinimages/alpin/alpinEntryImage.jpg"}
                        imageAlt={""}
                        buttonText={"Zeige mir alle"}
                        navLink={"/winterdienst/referenzen"}
                        gridArea={"wdReferenzenCard"}
                        isExternLink={false}
                    />

                    <AWDImageLinkCard
                        title={"Kunden Reklamation"}
                        subtitle={"Ihre Zufriedenheit ist unser Antrieb"}
                        image={"/imageRessource/alpinimages/angebot/angebotAngebotImage1.jpg"}
                        imageAlt={""}
                        buttonText={"Zum Kundenportal"}
                        navLink={"https://www.alpin-berlin.de/winterdienstreklamation/user/login.php"}
                        gridArea={"wdReklamationCard"}
                        isExternLink={true}
                    />
                </div>
            </div>
        </>
    )
}