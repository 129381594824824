
export default function AWDTextLinkContainer(
    props: { 
        title: string
    }
) {

    const title = props.title;

    return ( 
        <div style={{display: "flex", gap: "5px"}}>
            <p style={{fontWeight: 600, fontSize: 20}}>{title}</p>
            <a href="mailto:mail@alpin-berlin.de" style={{ fontSize: '20px', textDecoration: 'none'}}>mail@alpin-berlin.de</a>
        </div>
    )
}