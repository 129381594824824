
// Components
import AWDPageFooter from "../../Common/Components/PageFooter/AWDPageFooter";
import AWDPageNavbar from "../../Common/Components/PageNavbar/AWDPageNavbar";
import AWDAngebotInputFormular from "../../OfferRequest/Components/AWDAngebotInputFormular";

// Styling 
import "../Styling/AWDContactPage.css"
import AWDTextContainer from "./AWDTextContainer";
import AWDTextLinkContainer from "./AWDTextLinkContainer";

export default function AWDContactPage() {
    return ( 
        <div className="AWDPageContainer">
            <AWDPageNavbar />
            <div className="AWDBasicPageContent">
                <p className="basicPageTitle">Kontakt</p>
                <br />
                <p className="basicPageText" style={{fontWeight: 600}}>Anschrift</p>

                <p className="basicPageText">Alpin GmbH</p>
                <p className="basicPageText">Späthstraße 21-23</p>
                <p className="basicPageText">12359 Berlin</p>
                <br />

                <AWDTextContainer title={"Telefon:"} text={"030 6843601"}></AWDTextContainer>
                <AWDTextContainer title={"Fax:"} text={"030 6843610"}></AWDTextContainer>
                <AWDTextLinkContainer title={"E-Mail:"}/> 

                <br />

                <p style={{fontWeight: 600, fontSize: 25, color: '#333333'}}>Oder Sie nutzen unser Kontaktformular:</p>
                <AWDAngebotInputFormular/>
            </div>
            <AWDPageFooter /> 
        </div>
    )
}

