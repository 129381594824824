import "../Styling/AWDHomeTopicCard.css"
import { NavLink } from "react-router-dom";
import { AWDHomeTopicCardResource } from "../Entity/AWDHomeTopicCardResource"

export default function AWDHomeTopicCard(
    props: { 
        cardContent: AWDHomeTopicCardResource
    }
) {

    const cardContent = props.cardContent;

    return ( 
        <div className="homeTopicCard" id={cardContent.isTopic ? "" : "homeTopicCardBeratung"}>
            <div className="homeTopicCardImageContainer">
                <img src={cardContent.imageSrc} alt={cardContent.altText} className="homeTopicCardImage"/>
            </div>
            <div className="homeTopicCardContent">
                <div className="homeTopicCardTextContent">
                    <p className="homeTopicTitle">{cardContent.title}</p>
                    <div className="homeTopicCardSubTitleContainer">
                        {cardContent.subtitles.map((subtitle, index) => ( 
                            <p key={index} className="homeTopicSubTitle">{subtitle}</p>
                        ))}
                    </div>
                    <div className="homeTopicCardSpacer"></div>
                    <p className="homeTopicCardDescription">
                        {cardContent.description}
                    </p>
                </div>
                <NavLink to={cardContent.buttonLink} className="navigationButton" id="homeTopicCardNavigationButton">
                    {cardContent.buttonText}
                </NavLink>
            </div>
        </div>
    )
}