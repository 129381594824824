
import { AWDWinterdienstSelectOption } from "../Data/AWDWinterdienstSelectData";

export default function AWDEmpty(
    props: { 
        option: AWDWinterdienstSelectOption;
        selectedOption: AWDWinterdienstSelectOption;
        onClick: (option: AWDWinterdienstSelectOption) => void;
    }
) {

    const option = props.option;
    const onClick = props.onClick;
    const isSelected = props.option === props.selectedOption;
    
    return (
        <button
            className="winterdienstSelectButton"
            style={{ 
                background: isSelected ? 'rgb(84, 171, 233)' : 'rgba(255, 255, 255, 0)',
                transition: 'background-color 0.3s ease-in-out',
                color: isSelected ? 'white' : 'gray'
            }}
            onClick={() => onClick(option)}
        >
            {option}
        </button>
    )
}