
import AWDImageCard from "../../Common/Components/ImageCard/AWDImageCard";
import "../Styling/AWDFuhrparkFeatureCard.css"

export default function AWDFuhrparkFeatureCard(
    props: { 
    }
) {

    const title = "Unsere Winterdienst-Exzellenz in Berlin";
    const subtitle = "Qualität und Innovation";
    const description = "Als Ihr zuverlässiger Partner für den Winterdienst möchten wir Ihnen unsere besonderen Stärken vorstellen:"
    const bulletpoints = [
        ["Maßgeschneiderte Planung:", "Wir erstellen optimale Skizzen und detailgenaue Beschreibungen Ihrer Objekte, um einen effizienten Winterdienst sicherzustellen."],
        ["Realistische Tourenplanung:", "Unsere Tourenlängen sind sorgfältig geplant, um pünktliche und zuverlässige Einsätze sicherzustellen."],
        ["Mitarbeitertraining:", "Vor der Saison führen wir Trainings-Touren mit unseren Mitarbeitern durch, um sicherzustellen, dass sie bestens vorbereitet sind."],
        ["Erfahrene Teams:", "Unser Stamm- und Saisonpersonal ist hochqualifiziert und mit modernster Ausrüstung ausgestattet."],
        ["Kommunikation rund um die Uhr:", "Alle unsere Mitarbeiter sind mit Mobiltelefonen ausgestattet, und unser Büro ist 24 Stunden am Tag ohne Callcenter erreichbar."],
        ["Online-Reklamationssystem:", "Beschwerden und Anfragen werden dank unseres Online-Reklamationssystems schnell und effizient bearbeitet."],
        ["Vielfältiger Fuhrpark:", "Mit 74 Schneeräumfahrzeugen in verschiedenen Größen und Ausführungen sind wir bestens gerüstet."],
        ["Fahrzeugwartung:", "Unsere gesamte Flotte wird in beheizten Hallen untergestellt und von unserer eigenen Werkstatt rund um die Uhr, auch an Sonn- und Feiertagen, gewartet."],
        ["Mehr als Worte: Beispiel-Skizze:", "Hier sehen Sie eine Beispiel-Skizze für unsere Gehwegberäumung, die unsere Planung und Effizienz verdeutlicht."]
    ]
    const gridArea = "fuhrparkFeatureCard";
    const endText = "Vertrauen Sie auf unsere Erfahrung und unser Engagement, um den Winter in Berlin ohne Sorgen zu erleben."

    return ( 
        <div className="AWDFuhrparkFeatureCardContainer" style={{gridArea: gridArea}}>
            <p className="fuhrparkFeatureCardTitle">{title}</p>
            <p className="fuhrparkFeatureCardSubTitle">{subtitle}</p>
            <p className="fuhrparkFeatureCardDescription">{description}</p>
            <div className="fuhrparkFeatureCardBulletPointContainer">
                {bulletpoints.map((bulletpoint, index) => (
                    <div className="fuhrParkFeatureBulletPointTextContainer">
                        <p className="fuhrparkFeatureCardBulletPointTitle">{bulletpoint[0]}</p>
                        <p className="fuhrparkFeatureCardBulletPointText">{bulletpoint[1]}</p>
                    </div>
                ))}
            </div>
            <AWDImageCard 
                id={"fuhrparkFeatureImageSkizze"} 
                gridArea={""} 
                imageSrc={"/imageRessource/alpinimages/winterdienst/winterdienstMusterskizze.png"} 
                altText={""}
            />

            <p className="fuhrparkFeatureEndText">{endText}</p>
        </div>
    )
}