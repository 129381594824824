
export default function AWDTextContainer(
    props: { 
        title: string
        text: string 
    }
) {

    const title = props.title;
    const text = props.text;

    return ( 
        <div style={{display: "flex", gap: "5px"}}>
            <p style={{fontWeight: 600, fontSize: 20}}>{title}</p>
            <p style={{fontSize: 20}}>{text}</p>
        </div>
    )
}