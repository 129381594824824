
import "../Styling/AWDAngebotVariant.css"

export default function AWDAngebotVariant(
    props: { 
        title: string
        imageSrc: string
        imageAlt: string
        gridArea: string
    }
) {

    const title = props.title;
    const imageSrc = props.imageSrc;
    const imageAlt = props.imageAlt;
    const gridArea = props.gridArea;

    return ( 
        <div className="angebotVariantContainer" style={{ gridArea: gridArea}}>
            <div className="angebotVariantIconContainer">
                <img src={imageSrc} alt={imageAlt} className="icon" />
            </div>
            <p className="angebotVaraintTitle">{title}</p>
        </div>
    )
}